.media {
    width: 100%;
    height: 300px;
    display: flex;
    margin-top: 60px;

}

.media2 {
    width: 24.5%;
    height: 100%;
    margin-left: 10px;
    background-color: gray;
    border-radius: 10px;
}

.media2 video {
    width: 300px;
    height: 300px;
    border-radius: 10px;
}

.newfact{
    width: 100%;
    height: 100%;
    background-image: url(component/img/fback.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}

.facility {
    width: 50%;
    margin-top: 100px;
    height: 100%;
    margin-left: 300px;

}

.facility p {
    font-size: 22px;
    color: white;
    font-family: 'Times New Roman', Times, serif;
}










@media screen and (min-width: 300px) and (max-width: 600px) {
    .media {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        margin-top: 20px;
    }

    .media2 {
        width: 100%;
        height: 300px;
        margin-left: 0px;
        background-color: gray;
        border-radius: 10px;
        margin-top: 10px;
    }

    .media2 video {
        width: 100%;
        height: 300px;
        border-radius: 10px;
    }


    .facility {
        width: 100%;
        margin-top: 100px;
        height: auto;
        margin-left: 0px;
    }

    .facility p {
        font-size: 18px;
        color: black;
        margin-left: 10px;
    }


}


@media screen and (min-width: 601px) and (max-width: 1200px) {
    .media {
        width: 100%;
        height: 300px;
        display: flex;
        margin-top: 60px;
    }

    .media2 {
        width: 24.5%;
        height: 100%;
        margin-left: 10px;
        background-color: gray;
        border-radius: 10px;
    }

    .media2 video {
        width: 300px;
        height: 300px;
        border-radius: 10px;
    }


    .facility {
        width: 50%;
        margin-top: 100px;
        height: 100%;
        margin-left: 300px;
    }

    .facility p {
        font-size: 20px;
        color: black;
    }


}