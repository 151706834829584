.mains {
  width: 100%;
  height: 100%;
}

.head {
  width: 100%;
  height: 40px;
  background-color: cornflowerblue;
  display: flex;
}

.head2 {
  width: 60%;
  height: 100%;

}

.head2 a {
  color: white;
  margin-left: 40px;
  text-decoration: none;
  font-size: 22px;
}

.head3 {
  width: 40%;
  height: 100%;
  padding-top: 05px;
  color: white;
}



.navbar-brand,
.navbar-nav .nav-link {
  color: white;
  font-weight: bold;
  font-size: 18px;
  padding-left: 50px;
  margin-left: 40px;
  height: 55px;

}

.navbar-brand:hover,
.navbar-nav .nav-link:hover {
  color: #ffd700;
}


.carousel {
  width: 100%;
  height: 500px;
}

.carousel img {
  width: 100%;
  height: 500px;
}

.contain {
  width: 100%;
  height: auto;
  margin-top: 10px;
  display: flex;
}

.contain2 {
  width: 50%;
  height: 100%;

}

.contain2 h1 {
  margin-left: 60px;
  margin-top: 40px;
  font-size: 35px;
  color: black;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.contain2 span {
  color: red;
}


.contain2 p {
  margin-left: auto;
  margin-right: auto;
  text-align: justify;
  max-width: 82%;
  font-size: 16px;
  margin-top: 15px;
  font-style: italic;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.colors {
  color: goldenrod;
  font-weight: bold;
  background: none;
  font-size: 20px;
}


.contain3 {
  width: 50%;
  height: 100%;

}

.about1 {

  height: 200px;
  width: 100%;
  display: flex;
  margin-top: 15px;
}

.about2 {
  width: 45%;
  height: 100%;
  margin-left: 25px;
}

.about2 img {
  width: 100%;
  height: 100%;
}




.button {
  border-radius: 4px;
  background-color: red;
  border: none;
  color: #FFFFFF;
  text-align: center;
  font-size: 25px;
  padding: 0px;
  width: 200px;
  height: 60px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
  margin-left: 60px;
}

.button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
  color: white;
  font-weight: bold;
  font-size: 25px;
}

.button span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.button:hover span {
  padding-right: 25px;
}

.button:hover span:after {
  opacity: 1;
  right: 0;
}



.content {
  width: 100%;
  height: 400px;
  margin-top: 20px;
  background: url('./component/img/banner1.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

.content h1 {
  margin-left: 270px;
  padding-top: 150px;
  color: white;
}



.myDIV {
  height: 120px;
  padding-top: 10px;
  padding-left: 05px;
  font-weight: bold;
  border-radius: 10px;
  width: 30px;
  background-color: red;
  writing-mode: vertical-lr;
  text-orientation: mixed;
  position: fixed;
  top: 40%;
  left: 98%;
  transform: translate(-50%, -50%);
}

.myDIV a {
  color: white;
  font-size: 18px;
}



.myDIV2 {
  height: 90px;
  padding-top: 10px;
  padding-left: 02px;
  font-weight: bold;
  border-radius: 10px;
  width: 30px;
  background-color: red;
  writing-mode: vertical-lr;
  text-orientation: mixed;
  position: fixed;
  top: 60%;
  left: 98%;
  transform: translate(-50%, -50%);
}

.myDIV2 a {
  color: white;
  font-size: 18px;
  margin-top: 10px;
}



.myDIV2 a {
  color: white;
  font-size: 18px;
  margin-top: 10px;
  position: relative;
  /* Add this */
}

.myDIV2 a:hover::before {
  content: "Call";
  position: absolute;
  top: -20px;
  /* adjusted position */
  right: 100%;
  /* adjusted position */
  background-color: rgba(255, 255, 255, 0.8);
  padding: 5px;
  border-radius: 5px;
}

.myDIV2 a:last-child:hover::before {
  content: "WhatsApp";
  right: 100%;
  top: 50%;
}


.service {
  width: 100%;
  height: 280px;
  display: flex;
  margin-top: 20px;
}

.service2 {
  width: 24%;
  height: 100%;
  margin-left: 10px;
  background-color: lightgray;
  box-shadow: 2px 3px 5px -2px black;
  border-radius: 05px;
  color: black;
}


.service2:hover {
  background-color: hotpink;
  color: white;
}

.service2 h1 {
  text-align: center;
  font-size: 25px;
  padding-top: 20px;


}

.service2 img {
  width: 100%;
  height: 200px;
  margin-top: 20px;
}

.footer {
  width: 100%;
  height: 400px;
  margin-top: 30px;
  display: flex;
}

.foot {
  width: 33%;
  height: 100%;
  margin-left: 06px;
}

.foot img {
  margin-left: 60px;
  margin-top: 20px;
}

.foot p {
  margin-top: 30px;
  font-size: 18px;
  margin-left: auto;
  margin-right: auto;
  text-align: justify;
  max-width: 82%;
  color: black;
}

.foot h1 {
  margin-left: 60px;
  margin-top: 20px;
  font-size: 30px;
  text-align: center;
}

.foot h2 {
  margin-left: 175px;
  font-size: 20px;
  margin-top: 25px;

}

.link {
  text-decoration: none;
  color: black;
}

.disclamer {
  width: 100%;
  height: 50px;
  background-color: cornflowerblue;
  text-align: center;
  color: black;
  font-size: 17px;
  padding-top: 10px;
}

.map {
  width: 100%;
  height: 400px;
  margin-top: 20px;
}

.gallery {
  width: 100%;
  height: 300px;
  display: flex;
  margin-top: 20px;
}

.gallery2 {
  width: 32%;
  height: 100%;
  margin-left: 15px;
}

.gallery2 img {
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 300px) and (max-width: 600px) {

  .mains {
    width: 100%;
    height: 100%;
  }

  .head {
    width: 100%;
    height: 40px;
    background-color: cornflowerblue;
    display: flex;
  }

  .head2 {
    width: 50%;
    height: 100%;

  }

  .head2 a {
    color: white;
    margin-left: 07px;
    text-decoration: none;
    font-size: 13px;
    margin-top: 30px;
  }

  .head3 {
    width: 50%;
    height: 100%;
    padding-top: 05px;
    color: white;
  }





  .navbar-brand,
  .navbar-nav .nav-link {
    color: white;
    font-weight: bold;
    font-size: 18px;
    padding-left: 10px;
    margin-left: 10px;
    height: 60px;

  }

  .navbar-brand:hover,
  .navbar-nav .nav-link:hover {
    color: #ffd700;
  }

  .navbar-toggler{
    color: white;
    background-color: white;
  }

  .navbar-toggler-icon{
    border: white;
    color: white;
    background-color: white;
  }


  .carousel {
    width: 100%;
    height: 200px;
    margin-top: 10px;
  }

  .carousel img {
    width: 100%;
    height: 200px;
  }

  .contain {
    width: 100%;
    height: auto;
    margin-top: 10px;
    flex-direction: column;
    display: flex;
  }

  .contain2 {
    width: 100%;
    height: 100%;

  }

  .contain2 h1 {
    margin-left: 20px;
    margin-top: 40px;
    font-size: 30px;
    color: black;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }

  .contain2 span {
    color: red;
  }


  .contain2 p {
    margin-left: auto;
    margin-right: auto;
    text-align: justify;
    max-width: 90%;
    font-size: 16px;
    margin-top: 15px;
  }




  .contain3 {
    width: 100%;
    height: 100%;

  }

  .about1 {

    height: 170px;
    width: 100%;
    display: flex;
    margin-top: 15px;
  }

  .about2 {
    width: 48%;
    height: 100%;
    margin-left: 05px;
  }

  .about2 img {
    width: 100%;
    height: 100%;
  }




  .button {
    border-radius: 4px;
    background-color: red;
    border: none;
    color: #FFFFFF;
    text-align: center;
    font-size: 25px;
    padding: 0px;
    width: 200px;
    height: 60px;
    transition: all 0.5s;
    cursor: pointer;
    margin: 5px;
    margin-left: 60px;
  }

  .button span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
    color: white;
    font-weight: bold;
    font-size: 25px;
  }

  .button span:after {
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
  }

  .button:hover span {
    padding-right: 25px;
  }

  .button:hover span:after {
    opacity: 1;
    right: 0;
  }



  .content {
    width: 100%;
    height: 150px;
    margin-top: 20px;
    background: url('./component/img/banner1.jpg');
    background-repeat: no-repeat;
    background-size: cover;
  }

  .content h1 {
    margin-left: 10px;
    padding-top: 50px;
    color: white;
    font-size: 17px;
  }



  .myDIV {
    height: 120px;
    padding-top: 10px;
    padding-left: 05px;
    font-weight: bold;
    border-radius: 10px;
    width: 30px;
    background-color: red;
    writing-mode: vertical-lr;
    text-orientation: mixed;
    position: fixed;
    top: 40%;
    left: 95%;
    transform: translate(-50%, -50%);
  }

  .myDIV a {
    color: white;
    font-size: 18px;
  }



  .myDIV2 {
    height: 90px;
    padding-top: 10px;
    padding-left: 02px;
    font-weight: bold;
    border-radius: 10px;
    width: 30px;
    background-color: red;
    writing-mode: vertical-lr;
    text-orientation: mixed;
    position: fixed;
    top: 60%;
    left: 95%;
    transform: translate(-50%, -50%);
  }

  .myDIV2 a {
    color: white;
    font-size: 18px;
    margin-top: 10px;
  }



  .myDIV2 a {
    color: white;
    font-size: 18px;
    margin-top: 10px;
    position: relative;
    /* Add this */
  }

  .myDIV2 a:hover::before {
    content: "Call";
    position: absolute;
    top: -20px;
    /* adjusted position */
    right: 100%;
    /* adjusted position */
    background-color: rgba(255, 255, 255, 0.8);
    padding: 5px;
    border-radius: 5px;
  }

  .myDIV2 a:last-child:hover::before {
    content: "WhatsApp";
    right: 100%;
    /* adjusted position */
    top: 50%;
    /* adjusted position */
  }


  .service {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }

  .service2 {
    width: 100%;
    height: 300px;
    margin-left: 0px;
    margin-top: 15px;
    background-color: gray;
    box-shadow: 2px 3px 5px -2px black;
    border-radius: 05px;
  }

  .service2 h1 {
    text-align: center;
    font-size: 25px;
    padding-top: 50px;
    color: white;

  }

  .footer {
    width: 100%;
    height: auto;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
  }

  .foot {
    width: 100%;
    height: 100%;
    margin-left: 0px;
  }

  .foot img {
    margin-left: 30px;
    margin-top: 20px;
  }

  .foot p {
    margin-top: 30px;
    font-size: 18px;
    margin-left: auto;
    margin-right: auto;
    text-align: justify;
    max-width: 82%;
    color: black;
  }

  .foot h1 {
    margin-left: 30px;
    margin-top: 20px;
    font-size: 30px;
    text-align: center;
  }

  .foot h2 {
    margin-left: 125px;
    font-size: 20px;
    margin-top: 25px;

  }

  .link {
    text-decoration: none;
    color: black;
  }

  .disclamer {
    width: 100%;
    height: 60px;
    background-color: cornflowerblue;
    text-align: center;
    color: black;
    font-size: 15px;
    padding-top: 10px;
  }

  .map {
    width: 100%;
    height: 200px;
    margin-top: 20px;
  }

  
.gallery {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.gallery2 {
  width: 100%;
  height: 300px;
  margin-left: 0px;
  margin-top: 15px;
}

.gallery2 img {
  width: 100%;
  height: 100%;
}
}





@media screen and (min-width: 601px) and (max-width: 1200px) {
  .mains {
    width: 1200px;
    height: 100%;
  }

  .head {
    width: 100%;
    height: 40px;
    background-color: cornflowerblue;
    display: flex;
  }

  .head2 {
    width: 60%;
    height: 100%;

  }

  .head2 a {
    color: white;
    margin-left: 40px;
    text-decoration: none;
    font-size: 22px;
  }

  .head3 {
    width: 40%;
    height: 100%;

    padding-top: 05px;
    color: white;
  }





  .navbar-brand,
  .navbar-nav .nav-link {
    color: black;
    font-weight: bold;
    font-size: 18px;
    padding-left: 50px;
    margin-left: 40px;
    height: 40px;

  }

  .navbar-brand:hover,
  .navbar-nav .nav-link:hover {
    color: #ffd700;
  }


  .carousel {
    width: 100%;
    height: 500px;
  }

  .carousel img {
    width: 100%;
    height: 500px;
  }

  .contain {
    width: 100%;
    height: auto;
    margin-top: 10px;
    display: flex;
  }

  .contain2 {
    width: 50%;
    height: 100%;

  }

  .contain2 h1 {
    margin-left: 60px;
    margin-top: 40px;
    font-size: 35px;
    color: black;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }

  .contain2 span {
    color: red;
  }


  .contain2 p {
    margin-left: auto;
    margin-right: auto;
    text-align: justify;
    max-width: 82%;
    font-size: 16px;
    margin-top: 15px;
  }




  .contain3 {
    width: 50%;
    height: 100%;

  }

  .about1 {

    height: 200px;
    width: 100%;
    display: flex;
    margin-top: 15px;
  }

  .about2 {
    width: 45%;
    height: 100%;

    margin-left: 25px;
  }

  .about2 img {
    width: 100%;
    height: 100%;
  }




  .button {
    border-radius: 4px;
    background-color: red;
    border: none;
    color: #FFFFFF;
    text-align: center;
    font-size: 25px;
    padding: 0px;
    width: 200px;
    height: 60px;
    transition: all 0.5s;
    cursor: pointer;
    margin: 5px;
    margin-left: 60px;
  }

  .button span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
    color: white;
    font-weight: bold;
    font-size: 25px;
  }

  .button span:after {
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
  }

  .button:hover span {
    padding-right: 25px;
  }

  .button:hover span:after {
    opacity: 1;
    right: 0;
  }



  .content {
    width: 100%;
    height: 400px;
    margin-top: 20px;
    background: url('./component/img/banner1.jpg');
    background-repeat: no-repeat;
    background-size: cover;
  }

  .content h1 {
    margin-left: 270px;
    padding-top: 150px;
    color: white;
  }



  .myDIV {
    height: 120px;
    padding-top: 10px;
    padding-left: 05px;
    font-weight: bold;
    border-radius: 10px;
    width: 30px;
    background-color: red;
    writing-mode: vertical-lr;
    text-orientation: mixed;
    position: fixed;
    top: 40%;
    left: 98%;
    transform: translate(-50%, -50%);
  }

  .myDIV a {
    color: white;
    font-size: 18px;
  }



  .myDIV2 {
    height: 90px;
    padding-top: 10px;
    padding-left: 02px;
    font-weight: bold;
    border-radius: 10px;
    width: 30px;
    background-color: red;
    writing-mode: vertical-lr;
    text-orientation: mixed;
    position: fixed;
    top: 60%;
    left: 98%;
    transform: translate(-50%, -50%);
  }

  .myDIV2 a {
    color: white;
    font-size: 18px;
    margin-top: 10px;
  }



  .myDIV2 a {
    color: white;
    font-size: 18px;
    margin-top: 10px;
    position: relative;
    /* Add this */
  }

  .myDIV2 a:hover::before {
    content: "Call";
    position: absolute;
    top: -20px;
    /* adjusted position */
    right: 100%;
    /* adjusted position */
    background-color: rgba(255, 255, 255, 0.8);
    padding: 5px;
    border-radius: 5px;
  }

  .myDIV2 a:last-child:hover::before {
    content: "WhatsApp";
    right: 100%;
    /* adjusted position */
    top: 50%;
    /* adjusted position */
  }


  .service {
    width: 100%;
    height: 280px;
    display: flex;
    margin-top: 20px;
  }

  .service2 {
    width: 24%;
    height: 100%;
    margin-left: 10px;
    background-color: cornflowerblue;
    box-shadow: 2px 3px 5px -2px black;
    border-radius: 05px;
  }

  .service2 h1 {
    text-align: center;
    font-size: 25px;
    padding-top: 50px;
    color: white;

  }

  .service2 img {
    width: 100%;
    height: 200px;
    margin-top: 20px;
  }


  .footer {
    width: 100%;
    height: 400px;
    margin-top: 30px;
    display: flex;
  }

  .foot {
    width: 33%;
    height: 100%;
    margin-left: 06px;
  }

  .foot img {
    margin-left: 60px;
    margin-top: 20px;
  }

  .foot p {
    margin-top: 30px;
    font-size: 18px;
    margin-left: auto;
    margin-right: auto;
    text-align: justify;
    max-width: 82%;
    color: black;
  }

  .foot h1 {
    margin-left: 60px;
    margin-top: 20px;
    font-size: 30px;
    text-align: center;
  }

  .foot h2 {
    margin-left: 175px;
    font-size: 20px;
    margin-top: 25px;

  }

  .link {
    text-decoration: none;
    color: black;
  }

  .disclamer {
    width: 100%;
    height: 50px;
    background-color: cornflowerblue;
    text-align: center;
    color: black;
    font-size: 17px;
    padding-top: 10px;
  }

  .map {
    width: 100%;
    height: 400px;
    margin-top: 20px;
  }

  .gallery {
    width: 100%;
    height: 300px;
    display: flex;
    margin-top: 20px;
  }

  .gallery2 {
    width: 32%;
    height: 100%;
    margin-left: 15px;
  }

  .gallery2 img {
    width: 100%;
    height: 100%;
  }
}